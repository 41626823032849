import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CapacitorException, ExceptionCode } from '@capacitor/core';
import { DatetimePicker, PresentOptions, PresentResult } from '@capawesome-team/capacitor-datetime-picker';
import { OverlayEventDetail } from '@ionic/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { WeekDay } from 'calendar-utils';
import { DIRECTION_HORIZONTAL, Manager, Swipe } from 'hammerjs';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DateTimePickerComponent } from '../../../../components/date/dateTimePicker.component';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { DateHelper } from '../../../../helpers/dateHelper';
import { EDateTimePickerMode } from '../../../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../../../model/date/IDateTimePickerParams';
import { IPopoverItemParams } from '../../../../model/popover/IPopoverItemParams';
import { PlatformService } from '../../../../services/platform.service';
import { PopoverService } from '../../../../services/popover.service';
import { ESwipe } from '../../../gestures/model/eswipe';
import { ObserveArray } from '../../../observable/decorators/observe-array.decorator';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { secure } from '../../../utils/rxjs/operators/secure';
import { ECalendarView } from '../../models/ecalendar-view';
import { ICalendarEventClickedEvent } from '../../models/icalendar-event-clicked-event';
import { IViewRenderEmission } from '../../models/iview-render-emission';
import { ViewSelectorComponent } from '../view-selector/view-selector.component';

@Component({
	selector: "calao-calendar",
	templateUrl: "./calendar.component.html",
	styleUrls: ["./calendar.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.height]": "'100%'",
		"[style.max-height]": "'100%'",
		"[style.display]": "'flex'",
		"[style.flex-direction]": "'column'"
	}
})
export class CalendarComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	@Output("onDayClicked") private readonly moDayClickedEmitter = new EventEmitter<Date>();
	@Output("onCalendarEventClicked") private readonly moCalendarEventClickedEmitter = new EventEmitter<CalendarEvent>();
	@Output("onEventTimesChanged") private readonly moEventTimesChangedEmitter = new EventEmitter<CalendarEventTimesChangedEvent>();
	@Output("onViewChanged") private readonly moViewChangedEmitter = new EventEmitter<IViewRenderEmission>();

	@Input() public events? = new ObservableArray<CalendarEvent>();
	@Input() public locale?: string;
	@Input() public weekStartsOn?: number;
	@Input() public weekendDays?: number[];
	@Input() public openDayEventsTemplate?: TemplateRef<any>;
	@Input() public monthCellTemplate?: TemplateRef<any>;
	@Input() public listViewTemplate?: TemplateRef<any>;
	@Input() public eventTemplate?: TemplateRef<any>;
	@Input() public weekViewEventTemplate?: TemplateRef<any>;
	@Input() public todoItemTemplate?: TemplateRef<any>;
	@Input() public listItemSize?: string | number;
	@Input() public filterbarTemplate?: TemplateRef<any>;

	/** Libellé du bouton pour aller à la période précédente. */
	@Input() public previousPeriodButtonLabel?: string;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "previousPeriodButtonLabel" })
	public readonly observablePreviousPeriodButtonLabel = new ObservableProperty<string>();

	/** Libellé du bouton pour aller au jour actuel. */
	@Input() public currentPeriodButtonLabel?: string;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "currentPeriodButtonLabel" })
	public readonly observableCurrentPeriodButtonlabel = new ObservableProperty<string>("Today");

	/** Libellé du bouton pour aller à la période suivante. */
	@Input() public nextPeriodButtonLabel?: string;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "nextPeriodButtonLabel" })
	public readonly observableNextPeriodButtonLabel = new ObservableProperty<string>();

	/** Libellé du bouton pour aller à la vue mois. */
	@Input() public monthViewButtonLabel?: string;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "monthViewButtonLabel" })
	public readonly observableMonthViewButtonLabel = new ObservableProperty<string>("Month");

	/** Libellé du bouton pour aller à la vue semaine. */
	@Input() public weekViewButtonLabel?: string;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "weekViewButtonLabel" })
	public readonly observableWeekViewButtonLabel = new ObservableProperty<string>("Week");

	/** Libellé du bouton pour aller à la vue jour. */
	@Input() public dayViewButtonLabel?: string;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "dayViewButtonLabel" })
	public readonly observableDayViewButtonLabel = new ObservableProperty<string>("Day");

	/** Libellé du bouton pour aller à la vue liste. */
	@Input() public listViewButtonLabel?: string;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "listViewButtonLabel" })
	public readonly observableListViewButtonLabel = new ObservableProperty<string>("List");

	/** Date de la vue. */
	@Input() public viewDate?: Date;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "viewDate" })
	public readonly observableViewDate = new ObservableProperty<Date>();

	/** Nombre de segments affichés pour chaque heure. */
	@Input() public hourSegments?: number;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "hourSegments" })
	public readonly observableHourSegments = new ObservableProperty<number>(2);

	/** `true` si la plateforme actuelle est de type 'mobile'. */
	@Input() public isMobile?: boolean;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "isMobile" })
	public readonly observableIsMobile = new ObservableProperty<boolean>();

	/** Tableau des options avancées à afficher dans les popovers.. */
	@Input() public advancedOptions?: IPopoverItemParams[];
	@ObserveArray<CalendarComponent>("advancedOptions")
	public readonly observableAdvancedOptions = new ObservableArray<IPopoverItemParams>();

	private readonly moActiveDayIsOpenProp = new ObservableProperty<boolean | undefined>();
	/** quelque chose. */
	public set activeDayIsOpen(poValue: boolean | ObservableProperty<boolean>) {
		if (poValue instanceof ObservableProperty)
			this.moActiveDayIsOpenProp.bind(poValue.value$, this);
		else
			this.moActiveDayIsOpenProp.value = poValue;
	}
	/** Flux quelque chose. */
	public readonly activeDayIsOpen$: Observable<boolean | undefined> = this.moActiveDayIsOpenProp.value$;

	/** Vue à afficher. */
	@Input() public view?: ECalendarView;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "view" })
	public readonly observableView = new ObservableProperty<ECalendarView>(ECalendarView.Month);

	public CalendarView = ECalendarView;
	public refresh = new Subject<void>();
	public datePickerParams: IDateTimePickerParams = {
		pickerMode: EDateTimePickerMode.date
	};

	public weekStartOn = DAYS_OF_WEEK.MONDAY;

	public weekDays: ObservableArray<WeekDay> = new ObservableArray<WeekDay>();

	/** Compteur du nombre de tâches non datées. */
	@Input() public toDoItemCount?: number;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "toDoItemCount" })
	public readonly observableToDoItemCount = new ObservableProperty<number>();

	/** `true` pour afficher le moteur de recherche dans le header en plateforme mobile. Sinon `false`. */
	public readonly observableDisplayMobileHeaderSearch = new ObservableProperty<boolean>(false);

	/** Libellé du badge du composant de filtrage. */
	@Input() public filterBadgeLabel?: string | null;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "filterBadgeLabel" })
	public readonly observableFilterBadgeLabel = new ObservableProperty<string>();

	/** Total de filtres appliqués. */
	@Input() public totalNbFilters?: number;
	@ObserveProperty<CalendarComponent>({ sourcePropertyKey: "totalNbFilters" })
	public readonly observabletotalNbFilters = new ObservableProperty<number>();

	public currentDate?: string;

	public readonly observableTodayDate = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poChangeDetectorRef: ChangeDetectorRef,
		private readonly isvcPopover: PopoverService,
		private readonly isvcPlatform: PlatformService
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {

		DateHelper.getDays$().pipe(
			tap((pdDate: Date) => {
				this.observableTodayDate.value = pdDate.toString();
			}),
			secure(this)
		).subscribe();

		this.events?.changes$.pipe(
			tap(() => {
				this.refresh.next();
				this.detectChanges();
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();

		if (this.isvcPlatform.isMobile) {
			const loAgenda: HTMLElement | null = document.getElementById("agenda");

			if (loAgenda) {
				const loHammerJsManager = new Manager(loAgenda);
				loHammerJsManager.add(new Swipe({ direction: DIRECTION_HORIZONTAL }));

				loHammerJsManager.on(ESwipe.swipeLeft, (poEvent: HammerInput) => {
					this.changeDateAfterSwipe(poEvent.type as ESwipe);
					this.detectChanges();
				});
				loHammerJsManager.on(ESwipe.swipeRight, (poEvent: HammerInput) => {
					this.changeDateAfterSwipe(poEvent.type as ESwipe);
					this.detectChanges();
				});
			}
		}
	}

	private changeDateAfterSwipe(peSwipeDirection: ESwipe): void {
		switch (this.observableView.value) {
			case (ECalendarView.Month):
				if (this.observableViewDate.value) {
					const ldDate: Date = DateHelper.addMonths(this.observableViewDate.value, peSwipeDirection === ESwipe.swipeLeft ? 1 : -1);
					this.moActiveDayIsOpenProp.value = false;
					this.viewDate = ldDate;
				}
				break;
			case (ECalendarView.Week):
				if (this.observableViewDate.value) {
					const ldDate: Date = DateHelper.addWeeks(this.observableViewDate.value, peSwipeDirection === ESwipe.swipeLeft ? 1 : -1);
					this.viewDate = ldDate;
				}
				break;
			case (ECalendarView.Day):
				if (this.observableViewDate.value) {
					const ldDate: Date = DateHelper.addDays(this.observableViewDate.value, peSwipeDirection === ESwipe.swipeLeft ? 1 : -1);
					this.viewDate = ldDate;
				}
				break;
			case (ECalendarView.List):
				this.changeListAndDayViewDate(peSwipeDirection === ESwipe.swipeRight);
				break;
		}
	}

	public raiseDayClicked(poCalendarEventDayClikedEvent: ICalendarEventClickedEvent): void {
		if (!this.moActiveDayIsOpenProp.value)
			this.moActiveDayIsOpenProp.value = true;
		else if (DateHelper.areEqual(this.observableViewDate.value, poCalendarEventDayClikedEvent.date))
			this.moActiveDayIsOpenProp.value = false;

		this.moDayClickedEmitter.emit(poCalendarEventDayClikedEvent.date);
		this.observableViewDate.value = poCalendarEventDayClikedEvent.date;
	}

	public raiseEventTimesChanged(poCalendarEventTimesChangedEvent: CalendarEventTimesChangedEvent): void {
		this.moEventTimesChangedEmitter.emit(poCalendarEventTimesChangedEvent);
	}

	public raiseCalendarEventClicked(poEvent: CalendarEvent): void {
		this.moCalendarEventClickedEmitter.emit(poEvent);
	}

	public raiseViewRenderChanged(poEvent: CalendarMonthViewBeforeRenderEvent | CalendarWeekViewBeforeRenderEvent): void {
		this.initWeekDays(poEvent.period.start);
		this.moViewChangedEmitter.emit({
			view: this.observableView.value,
			period: { from: poEvent.period.start, to: poEvent.period.end }
		});

		if (this.observableView.value === ECalendarView.Week || this.observableView.value === ECalendarView.Day)
			this.scrollToCurrentTime();
	}

	private initWeekDays(pdStartDate: Date): void {
		const ldNow = new Date;
		const laWeekDays: WeekDay[] = [];
		for (let i = 0; i <= 6; i++) {
			const ldDayDate: Date = DateHelper.addDays(pdStartDate, i);
			const lnDay: number = ldDayDate.getDay();
			laWeekDays.push({
				date: ldDayDate,
				day: lnDay,
				isFuture: this.getIsFuture(ldNow, ldDayDate),
				isPast: this.getIsPast(ldNow, ldDayDate),
				isToday: DateHelper.areDayEqual(ldNow, ldDayDate),
				isWeekend: lnDay === 6 || lnDay === 0
			});
		}

		this.weekDays.resetArray(laWeekDays);
	}

	private getIsFuture(pdCurrentDate: Date, pdDate: Date): boolean {
		return DateHelper.compareTwoDates(pdDate, pdCurrentDate) > 0;
	}

	private getIsPast(pdCurrentDate: Date, pdDate: Date): boolean {
		return DateHelper.compareTwoDates(pdDate, pdCurrentDate) < 0;
	}

	public setView(peView: ECalendarView): void {
		if (peView === this.observableView.value)
			this.viewDate = new Date();

		this.observableView.value = peView;

		if (peView === ECalendarView.List) {
			this.moViewChangedEmitter.emit({
				view: ECalendarView.List,
				period: { from: DateHelper.resetDay(this.observableViewDate.value), to: DateHelper.fillDay(this.observableViewDate.value) }
			});

			this.moDayClickedEmitter.emit(this.observableViewDate.value);
		}
	}

	private scrollToCurrentTime(): void {
		setTimeout(() => {
			const ldDate = new Date();
			const lnCurrentHour: number = ldDate.getHours();
			const lnCurrentMinutes: number = ldDate.getMinutes();

			const laScrollableZones: HTMLCollectionOf<Element> = document.getElementsByClassName("cal-time-events");
			if (laScrollableZones.length > 0) {
				const loScrollableZone: Element | null = laScrollableZones.item(0);
				loScrollableZone?.scrollTo({ top: (60 * lnCurrentHour) - (lnCurrentMinutes < 30 ? 60 : 30) });
			}
		});
	}

	public getCalendarDateParam(peView: ECalendarView): string {
		const lsViewTitle = "ViewTitle";
		if (peView !== ECalendarView.List)
			return `${peView}${lsViewTitle}`;
		else
			return `${ECalendarView.Day}${lsViewTitle}`;
	}

	/** Affiche le timePicker correspondant à la plateforme.
	 * @param poDateTimePicker
	 */
	public onChooseDateTime(poDateTimePicker: DateTimePickerComponent): void {

		let loPickerOptions: PresentOptions = {
			mode: "date",
			value: DateHelper.transform(this.viewDate ?? new Date(), ETimetablePattern.isoFormat_hyphenWithHours),
			locale: "fr-FR",
			format: ETimetablePattern.isoFormat_hyphenWithHours,
			cancelButtonText: "Annuler"
		}

		DatetimePicker.present(loPickerOptions)
			.then((psResult: PresentResult) => this.viewDate = new Date(psResult.value))
			.catch((poError: any) => {
				if (poError instanceof CapacitorException && poError.code === ExceptionCode.Unimplemented)
					poDateTimePicker.pickDate();
			});
	}

	public ondDateChanged(pdDate: Date): void {
		this.viewDate = pdDate;
		this.activeDayIsOpen = false;

		this.moDayClickedEmitter.emit(pdDate);

		this.moViewChangedEmitter.emit({
			view: this.observableView.value,
			period: { from: DateHelper.resetDay(pdDate), to: DateHelper.fillDay(pdDate) }
		});
	}

	public changeListAndDayViewDate(isBackward?: boolean): void {
		let ldDate: Date;
		this.moActiveDayIsOpenProp.value = false;

		if (this.observableView.value === ECalendarView.List) {
			ldDate = DateHelper.addDays(this.observableViewDate.value, (!!isBackward ? -1 : 1));
			this.viewDate = ldDate;
			this.moViewChangedEmitter.emit({
				view: ECalendarView.List,
				period: { from: DateHelper.resetDay(ldDate), to: DateHelper.fillDay(ldDate) }
			});
		}
		else
			ldDate = this.viewDate;

		this.moDayClickedEmitter.emit(ldDate);
	}

	/** Affiche ou masque le moteur de recherche dans le header en plateforme mobile. */
	public toggleSearch(): void {
		this.observableDisplayMobileHeaderSearch.value = !this.observableDisplayMobileHeaderSearch.value;
	}

	public async presentViewSelectorAsync(poEvent: Event): Promise<void> {
		const loPopover = await this.isvcPopover.showCustomPopoverAsync(
			ViewSelectorComponent,
			{
				selectedView: this.observableView.value
			}, poEvent as MouseEvent);

		loPopover.onWillDismiss()
			.then((poValue?: OverlayEventDetail<ECalendarView>) => {
				if (poValue?.data)
					this.setView(poValue.data as ECalendarView);
			})
	}

	public presentAdvancedOptions(poEvent: Event): Promise<HTMLIonPopoverElement> {
		return this.isvcPopover.showPopoverAsync(this.observableAdvancedOptions, poEvent as MouseEvent)
	}

	//#endregion METHODS

}
